import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () => import("./login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "home",
    loadChildren: () => import("./home/home.module").then((m) => m.HomePageModule),
  },
  {
    path: "sites",
    loadChildren: () => import("./sites/sites.module").then((m) => m.SitesPageModule),
  },
  {
    path: "resources",
    loadChildren: () => import("./resources/resources.module").then((m) => m.ResourcesPageModule),
  },
  {
    path: "devices",
    loadChildren: () => import("./devices/devices.module").then((m) => m.DevicesPageModule),
  },
  {
    path: "sites-edit/:id",
    loadChildren: () => import("./sites-edit/sites-edit.module").then((m) => m.SitesEditPageModule),
  },
  {
    path: "exp/:token",
    loadChildren: () => import("./exp/exp.module").then((m) => m.ExpPageModule),
  },
  {
    path: "exp/:token/:session_id",
    loadChildren: () => import("./exp/exp.module").then((m) => m.ExpPageModule),
  },

  {
    path: "e/:token",
    loadChildren: () => import("./e/exp.module").then((m) => m.ExpPageModule),
  },
  {
    path: "e/:token/:session_id",
    loadChildren: () => import("./e/exp.module").then((m) => m.ExpPageModule),
  },
  // {
  //   path: 'exp/:company_id/:site_id/:function',
  //   loadChildren: () => import('./exp/exp.module').then( m => m.ExpPageModule)
  // },
  // {
  //   path: 'exp/:company_id/:site_id/:function/:device_id',
  //   loadChildren: () => import('./exp/exp.module').then( m => m.ExpPageModule)
  // },
  // {
  //   path: 'exp/:company_id/:site_id/:function/:device_id/:session_id',
  //   loadChildren: () => import('./exp/exp.module').then( m => m.ExpPageModule)
  // },
  {
    path: "support",
    loadChildren: () => import("./support/support.module").then((m) => m.SupportPageModule),
  },
  {
    path: "support-session/:session_id",
    loadChildren: () => import("./support-session/support-session.module").then((m) => m.SupportSessionPageModule),
  },
  {
    path: "users",
    loadChildren: () => import("./users/users.module").then((m) => m.UsersPageModule),
  },
  {
    path: "mappedin",
    loadChildren: () => import("./widgets/mappedin/mappedin.module").then((m) => m.MappedinPageModule),
  },
  {
    path: "qr",
    loadChildren: () => import("./widgets/qr/qr.module").then((m) => m.QrPageModule),
  },
  {
    path: "settings",
    loadChildren: () => import("./settings/settings.module").then((m) => m.SettingsPageModule),
  },
  {
    path: "reports",
    loadChildren: () => import("./reports/reports.module").then((m) => m.ReportsPageModule),
  },
  {
    path: "admin",
    loadChildren: () => import("./admin/admin.module").then((m) => m.AdminPageModule),
  },
  {
    path: "survey",
    loadChildren: () => import("./survey/survey.module").then((m) => m.SurveyPageModule),
  },
  {
    path: "media",
    loadChildren: () => import("./media/media.module").then((m) => m.MediaPageModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
